import React from "react";
import { graphql } from "gatsby";
import Seo from "react-seo-component";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { getImage } from "gatsby-plugin-image";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Layout, Image, Card, Button } from "../components";
import {
  Container,
  H1,
  Title,
  Flex,
  StyledProjectHeader,
  StyledProjectButtons,
} from "../components/styles";

export default function SingleProjectPage({ data, location }) {
  const {
    frontmatter: { title, featureImage, repository, liveUrl, techStack },
    body,
    slug,
    excerpt,
  } = data.project;

  const featuredImage = featureImage
    ? getImage(featureImage.childImageSharp.gatsbyImageData)
    : null;

  const { pathname } = location;

  const showTemplate = false;

  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <Layout pathname={pathname}>
      <Container>
        <Seo
          title={title}
          titleTemplate={siteTitle}
          description={excerpt}
          pathname={`${siteUrl}${slug}`}
          article={true}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          author={authorName}
          modifiedDate={new Date(Date.now()).toISOString()}
        />

        {showTemplate && <p>This is using src/templates/Project.js</p>}
        <StyledProjectHeader>
          <Title style={{ marginBottom: "0" }}>{title}</Title>
          <StyledProjectButtons>
            {repository && (
              <a href={repository} target="_blank" rel="noopener noreferrer">
                <Button>Repository</Button>
              </a>
            )}
            {liveUrl && (
              <a href={liveUrl} target="_blank" rel="noopener noreferrer">
                <Button>View Site</Button>
              </a>
            )}
          </StyledProjectButtons>
        </StyledProjectHeader>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2em",
            alignItems: "flex-start",
          }}
        ></div>

        {featuredImage && (
          <Image image={featuredImage} type="featured" alt="feature" />
        )}
        <main>
          <MDXRenderer techStack={techStack}>{body}</MDXRenderer>
        </main>

        <div
          style={
            {
              // marginTop: "200px",
            }
          }
        >
          <H1
            style={{
              margin: "50px 0",
              paddingTop: "50px",
              borderTop: "2px solid #f5f5f5",
            }}
          >
            Other projects
          </H1>
          <Flex>
            {data.otherProjects.nodes.map((node) => {
              const id = node.id;
              const title = node.frontmatter.title;
              const excerpt = node.excerpt;
              const slug = node.slug;
              const featuredImage =
                node.frontmatter.featureImage.childImageSharp.gatsbyImageData;
              return (
                <Card
                  key={id}
                  link={`/projects/${slug}`}
                  image={featuredImage && featuredImage}
                  heading={title}
                  excerpt={excerpt}
                />
              );
            })}
          </Flex>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query SingleProjectQuery($id: String!) {
    project: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        intro
        techStack
        repository
        liveUrl
        featureImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
      }
      tableOfContents
      body
      excerpt
    }
    otherProjects: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/projects/" }, id: { ne: $id } }
      limit: 2
    ) {
      nodes {
        id
        slug
        excerpt
        frontmatter {
          slug
          title
          techStack
          repository
          liveUrl
          featureImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
  }
`;
